import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 200,
//   bgcolor: "white",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   border: "0px solid white",
// };

export default function BottomNav() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  // const [open2, setOpen2] = useState(false);
  // const [nav, setNav] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem("loginData")) {
  //     setNav(true);
  //   } else {
  //     setNav(false);
  //   }
  // }, []);

  // const redirectProfile = () => {
  //   nav ? navigate("/") : navigate("/signin");
  // };

  const handleClick = (tabindex) => {
    setActiveTab(tabindex);
  };

  const redirectMine = () => {
    if (window.$loginData !== null) {
      handleClick(2)
      navigate("/profile")
    }
    else {
      navigate("/signin")
    }
  }

  return (
    <>
      <div className="bottomNav">
        <img src="bottom.png" alt="" />
        <div
          className={`botInfo ${activeTab === 0 ? "activeTab" : ""}`}
          style={{ left: "30px" }}
          onClick={() => {
            handleClick(0);
            navigate("/");
          }}
        >
          <div className="botImg">
            <i className="fa fa-home"></i>
          </div>
          <h4>Home</h4>
        </div>
        <div
          className={`botInfo ${activeTab === 1 ? "activeTab" : ""}`}
          style={{ width: "fit-content", margin: "auto", left: 0, right: "0" }}
          onClick={() => {
            handleClick(1);
            navigate("/promotion");
          }}
        >
          <div className="botImg">
            <img src="diamond.png" alt="" />
          </div>
          <h5>Promotion</h5>
        </div>
        {/* {nav && ( */}
        <div
          className={`botInfo ${activeTab === 2 ? "activeTab" : ""}`}
          style={{ right: "30px" }}
          onClick={redirectMine}>
          <div className="botImg">
            <i className="fa fa-user"></i>
          </div>
          <h4>Account</h4>
        </div>
        {/* )} */}
        {/* <div
          className="botInfo"
          // className= { color4 ? "changeColor" : "botInfo"
          // }
          onClick={redirectProfile}
        >
          <div className="botImg">
            <i className="fa fa-user"></i>
          </div>
          <h4>Profile</h4>
        </div> */}
      </div >
    </>
  );
}

/* BottomNav CSS */
