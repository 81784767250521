import { Link, useNavigate } from "react-router-dom";
import BottomNav from "../BottomNavigate/BottomNav";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Fetch_Url } from "../../Context/Context";
// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import PaidIcon from '@mui/icons-material/Paid';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SnoozeIcon from '@mui/icons-material/Snooze';
import InfoIcon from '@mui/icons-material/Info';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 230,
  bgcolor: "white",
  p: 4,
  boxShadow: 24,
};

export default function Profile() {
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  const [notify, setNotify] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [userdetail, setUserdetail] = useState([]);

  const logout = () => {
    navigator.vibrate(100);
    localStorage.removeItem("loginData") || navigate("/signin");
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const handleChange3 = (panel) => (event, isExpanded) => {
    setExpanded3(isExpanded ? panel : false);
  };
  const handleChange4 = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };

  const getUserinfo = () => {
    axios
      .post(
        `${Fetch_Url}/me.php?action=getuserinfo&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "resp data");
        setUserdetail(resp?.data?.user_Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bal, noticeInfo] = userdetail;
  // console.log(bal, noticeInfo, "bal, noticeInfo");

  useEffect(() => {
    getUserinfo();
  }, []);

  return (
    <>
      <BottomNav />
      <div className="profileBox">
        <div className="profileHead">
          <div className="profileImg">
            <img src="avatar-people-person-business.jpg" alt="" />
          </div>
          <div className="profileText">
            <h3>User : {bal?.username}</h3>
            <h3>ID : {bal?.usercode}</h3>
          </div>
          <div
            className="notifyIcon"
            onClick={() => {
              navigator.vibrate(100);
              setNotify(true);
            }}
          >
            <i className="fa fa-bell"></i>
          </div>
          <Modal
            open={notify}
            onClose={() => {
              setNotify(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="signinModalTxt">
                <h2>Notice</h2>
              </div>
              <div className="signinModal">
                <h5>{noticeInfo?.notice}</h5>
              </div>
              <div className="notifyBtn">
                <button
                  onClick={() => {
                    setNotify(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="whiteBox">
          <div className="balBox">
            <h4>Total Balance</h4>
            <h3>&#x20b9;{bal?.balance}</h3>
          </div>
          <div className="balIcons">
            <div className="balIconInfo" onClick={() => { navigate("/recharge", { state: bal }) }}>
              <PaidIcon />
              <h4>Deposit</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/withdrawal", { state: bal }) }}>
              <PaymentIcon />
              <h4>Withdraw</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/reward") }}>
              <CreditScoreIcon />
              <h4>Rewards</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/intrest") }}>
              <LocalAtmIcon />
              <h4>Intrest</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="profileCont">
        <div className="profileList">
          {/* <div
            className="listBox"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fas fa-sign-in-alt"></i>
            <h4>Sign In</h4>
            <i className="fa fa-angle-down"></i>
          </div>
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="signinModalTxt">
                <h2>Sign In</h2>
              </div>
              <div className="signinModal">
                <h5>Total:</h5>
                <h5> 1 Days</h5>
              </div>
              <div className="signinModal">
                <h5>Total Rebates:</h5>
                <h5>&#x20b9; 0</h5>
              </div>
              <div className="signinModal">
                <h5>Status:</h5>
                <h5>Had Signed In</h5>
              </div>
              <div className="signinModalBtn">
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  CANCEL
                </button>
                <button
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  SIGN IN
                </button>
              </div>
            </Box>
          </Modal> */}
          <a href="https://t.me/win1_club" target="_blank">
            <div className="listBox">
              <i className="fab fa-telegram"></i>
              <h4>Join Telegram</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </a>
          <Link to="/promotion">
            <div
              className="listBox"
            // onClick={() => {
            //   setOpen2(true);
            // }}
            >
              <i className="fa fa-gift"></i>
              <h4>Promotion</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          {/* <Modal
            open={open2}
            onClose={() => {
              setOpen2(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="promotionTab">
                <div className="signinModalTxt">
                  <h2>Notice</h2>
                </div>
                <div className="signinModal">
                  <h5>
                    Promotion Bonus:₹ 0 Total People 0 Contribution ₹ 0 My
                    Promotion Code 58Q18JQ5 My Promotion Link
                    https://colourwins.in//#/register?r_code=58Q18JQ5 Copy Link
                    search ID Phone Water reward First reward 0-10 of 0 Home
                    Search Win My Notice When your friends trade, you will also
                    receive a 30% commission. Therefore, the more friends you
                    invite, the higher your commission. There is a fixed income
                    every day, the commission is permanent, but the reward is
                    only onceWhen they make money, they will invite their
                    friends to join them, and then you can get a 20% commission.
                    In this way, your team can spread quickly. Therefore, I hope
                    everyone can use our platform to make money, make money, and
                    make money!When they make money, they will invite their
                    friends to join them, and then you can get a 20% commission.
                    In this way, your team can spread quickly. Therefore, I hope
                    everyone can use our platform to make money, make money, and
                    make money!Level 1 commission: Friends who join through your
                    own link belong to your level, when they trade, you will get
                    30% commission.Tier 2 commission: Friends who join through
                    your friend link belong to your secondary commission. When
                    they trade, you can get 20% commission.Level 3 commission:
                    Friends who join through friends of friends belong to your
                    level 3. When they trade, you get 10% commission.Promotional
                    rewards: 10% bonus amount for the first recharge after the
                    first-level lower level joins. If your friend joins through
                    your invitation and recharges 1000 for the first time, you
                    will get 200.
                  </h5>
                </div>
                <div className="promotionTabBtn">
                  <button
                    onClick={() => {
                      setOpen2(false);
                      navigate("/promotion");
                    }}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </Box>
          </Modal> */}
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="proBar"
              sx={{ background: "white" }}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ background: "white" }}
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-wallet"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  Wallet
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ background: "white" }}
              >
                <Typography>
                  <Link to="/recharge" state={bal}>
                    <div className="miniListBox">
                      <h4>Recharge</h4>
                    </div>
                  </Link>
                  <Link to="/withdrawal" state={bal}>
                    <div className="miniListBox">
                      <h4>Withdrawal</h4>
                    </div>
                  </Link>
                  <Link to="/transactions">
                    <div className="miniListBox">
                      <h4>Transactions</h4>
                    </div>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <Link to="/bankcard">
            <div className="listBox">
              <i className="fa fa-credit-card"></i>
              <h4>Bank Card</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          <div>
            <Accordion
              expanded={expanded2 === "panel2"}
              onChange={handleChange2("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-shield-alt"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  Account Security
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Link to="/forgotpassword">
                    <div className="miniListBox">
                      <h4>Reset Password</h4>
                    </div>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              expanded={expanded3 === "panel3"}
              onChange={handleChange3("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-download"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  App Download
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="miniListBox">
                    <a
                      href="https://play350.com/Play350.apk"
                      download="Play350"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4>Android Download</h4>
                    </a>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <Link to="/complaints">
            <div className="listBox">
              <i className="fa fa-file-text"></i>
              <h4>Complaints & Suggestions</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          <div>
            <Accordion
              expanded={expanded4 === "panel4"}
              onChange={handleChange4("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fas fa-comment-alt"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  About
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="miniListBox">
                    <h4>Privacy Policy</h4>
                  </div>
                  <div className="miniListBox">
                    <h4>Risk Disclosure Agreement</h4>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="serviceTxt">
          <h4>Service Center</h4>
        </div>
        <div className="allserviceIcons">
          <div className="serviceIcon">
            <SettingsIcon />
            <h4>Settings</h4>
          </div>
          <div className="serviceIcon">
            <FeedbackIcon />
            <h4>Feedback</h4>
          </div>
          <div className="serviceIcon">
            <SnoozeIcon />
            <h4>Notification</h4>
          </div>
          <div className="serviceIcon" >
            <InfoIcon />
            <h4>About Us</h4>
          </div>
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            setLogoutModal(true);
          }}
        >
          <button>Logout</button>
        </div>
        <Modal
          open={logoutModal}
          onClose={() => {
            setLogoutModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="signinModalTxt">
              <h2>Confirm</h2>
            </div>
            <div className="signinModal">
              <h5>Do you want to Logout?</h5>
            </div>
            <div className="signinModalBtn">
              <button
                onClick={() => {
                  setLogoutModal(false);
                }}
              >
                CANCEL
              </button>
              <button onClick={logout}>YES</button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

// Profile CSS
